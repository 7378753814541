import axios from '@axios'

export default {
  namespaced: true,
  state: {
    collections: [],
    dataItem: {},
  },
  getters: {},
  mutations: {

    setDataItem(state, item) {
      state.dataItem = item
    },
  },
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wallet/history', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWalletBalance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wallet/balance', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id, params = {} }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/my/orders/${id}`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/my/orders/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },


    addOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/my/orders', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrder(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axios.patch(`/my/orders/${id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOrder(ctx, orderId) {

      return new Promise((resolve, reject) => {
        axios
          .delete(`/my/orders/${orderId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
