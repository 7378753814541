<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- -->

      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>سجل المحفظة</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- add-->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            <!-- <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="order-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select> -->
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refObjectsListTable"
      :items="fetchObjects"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- <template #head(status)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template> -->
      <!-- Column: User -->

      <!-- Column: Id -->
      <!-- <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-order-preview', params: { id: data.item.id } }"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template> -->
      <!-- <template #cell(amount)="data">
        {{ __formatMoney(data.item.amount) }}
      </template> -->

      <!-- Column: Object Status -->
      <template #cell(approval_string)="data">
        <b-badge pill :variant="`light-${resolveObjectStatusVariant(data.item.approval_string)}`" class="text-capitalize">
          {{ data.item.approval_string }}
        </b-badge>
      </template>
      <!-- <template #cell(payment_status)="data">
        <b-badge pill :variant="`light-${resolvePaymentStatusVariant(data.item.payment_status)}`" class="text-capitalize">
          {{ data.item.payment_status }}
        </b-badge>
      </template> -->

      <!-- Column: Issued Date -->
      <!-- <template #cell(date)="data">
        <span class="text-nowrap">
          {{ data.item.date }}
        </span>
      </template> -->

      <!-- <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-link :to="{ name: 'admin.orders.show', params: { id: data.item.id } }" class="font-weight-bold">
            <feather-icon :id="`order-row-${data.item.id}-preview-icon`" icon="EyeIcon" size="16" class="mx-1 cursor-pointer" />
          </b-link>
      
        </div>
      </template> -->
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalObjects"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useWallet from "./useWallet";
import router from "@/router";
import walletStoreModule from "./walletStoreModule.js";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect
  },
  setup() {
    const _APP_STORE_MODULE_NAME = "wallets";

    // Register module
    if (!store.hasModule(_APP_STORE_MODULE_NAME)) {
      store.registerModule(_APP_STORE_MODULE_NAME, walletStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(_APP_STORE_MODULE_NAME)) store.unregisterModule(_APP_STORE_MODULE_NAME);
    });

    const {
      fetchObjects,
      tableColumns,
      perPage,
      currentPage,
      totalObjects,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refObjectsListTable,

      statusFilter,

      refetchData,
      resolveObjectStatusVariant,
      resolvePaymentStatusVariant,
      resolveObjectStatusVariantAndIcon,
      resolveClientAvatarVariant
    } = useWallet();

    const editObject = order => {
      store.commit("wallet/setDataItem", order);
      router.push({ name: "admin.wallet.edit", params: { id: order.id } });
    };
    const deleteObject = order => {
      //  this.orderData.items.splice(index, 1);
      store.dispatch("orders/deleteObject", order.id).then(response => {
        refetchData();
      });
    };
    const statusOptions = ["Downloaded", "Draft", "Paid", "Partial Payment", "Past Due"];

    return {
      fetchObjects,
      tableColumns,
      perPage,
      currentPage,
      totalObjects,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refObjectsListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveObjectStatusVariant,
      resolvePaymentStatusVariant,
      resolveObjectStatusVariantAndIcon,
      resolveClientAvatarVariant,
      editObject,
      deleteObject
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.order-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "~@resources/scss/vue/libs/vue-select.scss";
</style>
